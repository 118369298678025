import { ApiUrl } from '../util/api.url'
import FilterResponseModel from '../model/FilterResponseModel'
import { DefaultFilterResponseModel } from '../model/DefaultFilterResponseModel'
import { BaseService } from './base.service'

export class FiltertService {
  private static baseUrl = ApiUrl.webapiurl() + '/Filter/'

  static async GetFilters(): Promise<FilterResponseModel[]> {
    const response = await BaseService.getApiCall(this.baseUrl + 'GetFilters')
    return response.data
  }

  static async GetDefaultFilters(): Promise<DefaultFilterResponseModel[]> {
    const response = await BaseService.getApiCall(this.baseUrl + 'GetDefaultFilters')
    return response.data
  }
}
